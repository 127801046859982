// Copyright 2021 SeekOps Inc.
import {
  GridRowsProp,
  GridColDef,
  GridSortModel,
  GridPaginationModel,
  GridFilterModel,
} from "@mui/x-data-grid-premium";
import { IPremiumTableAction } from "../../components/UI/PremiumTable/PremiumTable.interfaces";

// Use const enums for better autocompletion of action type names. These will
// be compiled away leaving only the final value in your compiled code.
//
// Define whichever naming conventions you'd like for your action types, but
// personally, I use the `@@context/ACTION_TYPE` convention, to follow the

// convention of Redux's `@@INIT` action.
export enum premiumTableActionTypes {
  SET_ROWS = "@@premiumTable/SET_ROWS",
  SET_COLUMNS = "@@premiumTable/SET_COLUMNS",
  SET_TABLE_ACTIONS = "@@premiumTable/SET_TABLE_ACTIONS",
  SET_SEARCH_MODEL = "@@premiumTable/SET_SEARCH_MODEL",
  SET_SORT_MODEL = "@@premiumTable/SET_SORT_MODEL",
  SET_PAGINATION_MODEL = "@@premiumTable/SET_PAGINATION_MODEL",
  SET_FILTER_MODEL = "@@premiumTable/SET_FILTER_MODEL",
  SET_MANDATORY_FILTER_FIELDS = "@@premiumTable/SET_MANDATORY_FILTER_FIELDS",
  SET_DATA_ENDPOINT = "@@premiumTable/SET_DATA_ENDPOINT",
  APPLY_IS_LOADING = "@@premiumTable/APPLY_IS_LOADING",
  APPLY_COLUMNS = "@@premiumTable/APPLY_COLUMNS",
  SET_ACTION_ID = "@@premiumTable/SET_ACTION_ID",
  SET_CURRENT_ACTION = "@@premiumTable/SET_CURRENT_ACTION",
}

// Declare state types with `readonly` modifier to get compile time immutability
// https://github.com/piotrwitek/react-redux-typescript-guide#state-with-type-level-immutability
export interface IPremiumTableState {
  rows: GridRowsProp;
  columns: GridColDef[];
  tableActions?: (
    | IPremiumTableAction
    | ((params: any) => IPremiumTableAction)
  )[];
  searchModel: string | undefined;
  sortModel: GridSortModel;
  paginationModel?: GridPaginationModel;
  filterModel?: GridFilterModel;
  mandatoryFilterFields?: string[];
  dataEndpoint: string;
  isLoading?: boolean;
  currentAction: "delete" | "restore" | null;
  actionId: number | null;
}

export default premiumTableActionTypes;
