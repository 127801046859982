// Copyright 2021 SeekOps Inc.
// Use const enums for better autocompletion of action type names. These will
// be compiled away leaving only the final value in your compiled code.
//
// Define whichever naming conventions you'd like for your action types, but
// personally, I use the `@@context/ACTION_TYPE` convention, to follow the

import { LabelPayloadINF } from "../../components/GISMap/GISMapFeatures/Labels/LabelManager";
import { SketchPayloadINF } from "../../components/GISMap/GISMapFeatures/PolygonSketch/PolygonSketch";
import SceneView from "@arcgis/core/views/SceneView";
import GISMapFeature from "../../components/GISMap/GISMapFeatures/GISMapFeature";

// convention of Redux's `@@INIT` action.
export enum geoMapActionTypes {
  SET_ACTIVE_HEAT_MAPS = "@@geomap/SET_ACTIVE_HEAT_MAPS",
  SET_EMISSION_IDS = "@@geomap/SET_EMISSION_IDS",
  SET_SKETCH_PAYLOAD = "@@geomap/SET_SKETCH_PAYLOAD",
  SET_LABEL_SKETCH_PAYLOAD = "@@geomap/SET_LABEL_SKETCH_PAYLOAD",
  SET_SCENE_VIEW = "@@geomap/SET_SCENE_VIEW",
  SET_MAP_FEATURES = "@@geomap/SET_MAP_FEATURES",
}

// Declare state types with `readonly` modifier to get compile time immutability
// https://github.com/piotrwitek/react-redux-typescript-guide#state-with-type-level-immutability
export interface geoMapState {
  activeHeatMaps: ActiveHeatMap[];
  emissionIds: number[];
  sketchPayload: SketchPayloadINF | null;
  labelSketchPayload: LabelPayloadINF | null;
  sceneView: SceneView | null;
  mapFeatures: GISMapFeature[];
}

export interface ActiveHeatMap {
  fluxplaneRunId: number;
  emissionId: number;
  highlights: any;
}

export default geoMapActionTypes;
