// Copyright 2021 SeekOps Inc.

import { FC, ReactNode, useCallback, useEffect, useState } from "react";
import {
  MapContext,
  IMapContextProps,
  TRendererSelection,
  IEmissionChange,
  AltitudeChannel,
  AltitudesState,
} from "./GISMapContext";
import SceneView from "@arcgis/core/views/SceneView";
import { FluxplaneRunStatus } from "../../FluxplaneRun/FluxplaneRun.interfaces";
import { GISMapFeaturesToggles } from "../GISMap.interfaces";
import { isEqual } from "lodash";
import GISMapFeature from "../GISMapFeatures/GISMapFeature";
import { ActiveHeatMap } from "../../FluxplaneRun/FluxplaneRunEmissions/FluxplaneRunEmissions";

type MapContextProviderProps = {
  initialState?: IMapContextProps;
  children: ReactNode;
};

const GISMapContextProvider: FC<MapContextProviderProps> = ({
  initialState,
  children,
}) => {
  const [sceneView, setSceneView] = useState<SceneView | null>(
    initialState?.sceneView || null
  );

  const [activeHeatmaps, setActiveHeatmaps] = useState<ActiveHeatMap[]>(
    initialState?.activeHeatmaps || []
  );

  const [prevActiveHeatmaps, setPrevActiveHeatmaps] = useState<ActiveHeatMap[]>(
    []
  );

  const [addedActiveHeatmaps, setAddedActiveHeatmaps] = useState<
    ActiveHeatMap[]
  >([]);

  const [removedActiveHeatmaps, setRemovedActiveHeatmaps] = useState<
    ActiveHeatMap[]
  >([]);

  const [activeFluxplaneRunStatus, setActiveFluxplaneRunStatus] =
    useState<FluxplaneRunStatus | null>(
      initialState?.activeFluxplaneRunStatus || null
    );

  const [fluxplaneRunStatuses, setFluxplaneRunStatuses] = useState<
    FluxplaneRunStatus[]
  >(initialState?.fluxplaneRunStatuses || []);

  const [prevFluxplaneRunStatuses, setPrevFluxplaneRunStatuses] = useState<
    FluxplaneRunStatus[]
  >([]);

  const [removedFluxplaneRunStatuses, setRemovedFluxplaneRunStatuses] =
    useState<FluxplaneRunStatus[]>([]);

  const [surveyId, setSurveyId] = useState<number | null>(
    initialState?.surveyId || null
  );

  const [prevSurveyId, setPrevSurveyId] = useState<number | null>(null);

  const [showUnquantifiedEmissions, setShowUnquantifiedEmissions] =
    useState<boolean>(true);

  const [primaryFluxplaneRunStatus, setPrimaryFluxplaneRunStatus] =
    useState<FluxplaneRunStatus | null>(
      initialState?.primaryFluxplaneRunStatus || null
    );

  const [features, setFeatures] = useState<GISMapFeaturesToggles | null>(null);

  const [prevFeatures, setPrevFeatures] =
    useState<GISMapFeaturesToggles | null>(null);

  const [isEmissionsDiscreteColormap, setIsEmissionsDiscreteColormap] =
    useState<boolean>(false);

  const [isFlightpathByDiscreteColormap, setIsFlightpathByDiscreteColormap] =
    useState<boolean>(false);

  const [isFlightpathByGradientColormap, setIsFlightpathByGradientColormap] =
    useState<boolean>(true);

  const [isFlightpathByTrims, setIsFlightpathByTrims] =
    useState<boolean>(false);

  const [flightpathRendererSelection, setFlightpathRendererSelection] =
    useState<TRendererSelection>("gradient");

  const [selectedFluxplaneActionsId, setSelectedFluxplaneActionsId] = useState<
    number | null
  >(null);

  const [mapFeatureInstances, setMapFeatureInstances] = useState<
    GISMapFeature[]
  >([]);

  const [areControlsVisible, setAreControlsVisible] = useState<boolean>(true);

  const [mapListeners, setMapListeners] = useState<IHandle[]>([]);

  const [lastEmissionChange, setLastEmissionChange] =
    useState<IEmissionChange | null>(null);

  const [altitudeChannel, setAltitudeChannel] = useState<AltitudeChannel>("lidar_altitude");
  const [queryAltitudeChannel, setQueryAltitudeChannel] = useState<string>("lidar");

  const [disableAltitudeFlags, setDisableAltitudeFlags] = useState<AltitudesState>({
    lidar_altitude: false,
    altitude_best_estimate: false,
    gps_altitude: false,
  });

  const reset = () => {
    // clear all variables and destroy/remove resources that would
    // otherwise leak memory; for use when survey changes
    setSurveyId(null);
    if (sceneView) {
      sceneView.destroy();
      setSceneView(null);
    }
    setActiveHeatmaps([]);
    setPrevActiveHeatmaps([]);
    setAddedActiveHeatmaps([]);
    setRemovedActiveHeatmaps([]);
    setActiveFluxplaneRunStatus(null);
    setFluxplaneRunStatuses([]);
    setPrevFluxplaneRunStatuses([]);
    setRemovedFluxplaneRunStatuses([]);
    setPrevSurveyId(null);
    setShowUnquantifiedEmissions(true);
    setPrimaryFluxplaneRunStatus(null);
    setFeatures(null);
    setPrevFeatures(null);
    setIsEmissionsDiscreteColormap(false);
    setIsFlightpathByDiscreteColormap(false);
    setIsFlightpathByGradientColormap(true);
    setIsFlightpathByTrims(false);
    setFlightpathRendererSelection("gradient");
    setSelectedFluxplaneActionsId(null);
    setMapFeatureInstances([]);
    setAreControlsVisible(true);
    setAltitudeChannel("lidar_altitude");
    if (mapListeners.length) {
      mapListeners.forEach((listener) => {
        if (listener.remove) {
          listener.remove();
        }
      });
      setMapListeners([]);
    }
    setLastEmissionChange(null);
  };

  const isEmissionHeatmapCurrentlyActive = (emissionId: number): boolean => {
    return activeHeatmaps.some((activeHeatmap) => {
      return activeHeatmap.emissionId === emissionId;
    });
  };

  // help track fluxplanerun changes
  useEffect(() => {
    // Calculate the removed fluxplane run statuses
    const removed = prevFluxplaneRunStatuses.filter(
      (prevFluxplaneRunStatus) =>
        !fluxplaneRunStatuses.includes(prevFluxplaneRunStatus)
    );
    setRemovedFluxplaneRunStatuses(removed);

    // Update the previous context value
    setPrevFluxplaneRunStatuses(fluxplaneRunStatuses);
  }, [fluxplaneRunStatuses, prevFluxplaneRunStatuses]);

  // help track added heatmaps
  useEffect(() => {
    // Calculate the removed fluxplane run statuses
    const removed = prevActiveHeatmaps.filter(
      (prevActiveHeatmaps) => !activeHeatmaps.includes(prevActiveHeatmaps)
    );
    setRemovedActiveHeatmaps(removed);

    // Calculate the added fluxplane run statuses
    const added = activeHeatmaps.filter(
      (activeHeatmap) => !prevActiveHeatmaps.includes(activeHeatmap)
    );
    setAddedActiveHeatmaps(added);

    // Update the previous context value
    setPrevActiveHeatmaps(activeHeatmaps);
  }, [activeHeatmaps, prevActiveHeatmaps]);

  // handle features
  useEffect(() => {
    if (!isEqual(features, prevFeatures)) {
      setPrevFeatures(features);
    }
  }, [features, prevFeatures]);

  // this useEffect is solely to log changes in values for debugging purposes
  useEffect(() => {
    // log changes to the context values
    console.groupCollapsed("GISMapContextProvider");
    console.debug("surveyId", surveyId);
    console.debug("prevSurveyId", prevSurveyId);
    console.debug("sceneView", sceneView);
    console.debug("activeHeatmaps", activeHeatmaps);
    console.debug("prevActiveHeatmaps", prevActiveHeatmaps);
    console.debug("addedActiveHeatmaps", addedActiveHeatmaps);
    console.debug("removedActiveHeatmaps", removedActiveHeatmaps);
    console.debug("activeFluxplaneRunStatus", activeFluxplaneRunStatus);
    console.debug("fluxplaneRunStatuses", fluxplaneRunStatuses);
    console.debug("prevFluxplaneRunStatuses", prevFluxplaneRunStatuses);
    console.debug("removedFluxplaneRunStatuses", removedFluxplaneRunStatuses);
    console.debug("showUnquantifiedEmissions", showUnquantifiedEmissions);
    console.debug("primaryFluxplaneRunStatus", primaryFluxplaneRunStatus);
    console.debug("features", features);
    console.debug("prevFeatures", prevFeatures);
    console.debug("isEmissionsDiscreteColormap", isEmissionsDiscreteColormap);
    console.debug(
      "isFlightpathByDiscreteColormap",
      isFlightpathByDiscreteColormap
    );
    console.debug(
      "isFlightpathByGradientColormap",
      isFlightpathByGradientColormap
    );
    console.debug("isFlightpathByTrims", isFlightpathByTrims);
    console.debug("flightpathRendererSelection", flightpathRendererSelection);
    console.debug("selectedFluxplaneActionsId", selectedFluxplaneActionsId);
    console.debug("mapFeatureInstances", mapFeatureInstances);
    console.debug("areControlsVisible", areControlsVisible);
    console.debug("mapListeners", mapListeners);
    console.debug("lastEmissinoChange", lastEmissionChange);
    console.debug("altitudeChannel", altitudeChannel);
    console.groupEnd();
  }, [
    surveyId,
    prevSurveyId,
    sceneView,
    activeHeatmaps,
    addedActiveHeatmaps,
    removedActiveHeatmaps,
    prevActiveHeatmaps,
    activeFluxplaneRunStatus,
    fluxplaneRunStatuses,
    prevFluxplaneRunStatuses,
    removedFluxplaneRunStatuses,
    showUnquantifiedEmissions,
    primaryFluxplaneRunStatus,
    features,
    prevFeatures,
    isEmissionsDiscreteColormap,
    isFlightpathByDiscreteColormap,
    isFlightpathByGradientColormap,
    isFlightpathByTrims,
    flightpathRendererSelection,
    selectedFluxplaneActionsId,
    mapFeatureInstances,
    areControlsVisible,
    mapListeners,
    lastEmissionChange,
    altitudeChannel,
  ]);

  const mapPropertyToAltitude = (property: string) => {
    const propertyToAltitudeMapper: {[key: string]: string} = {
      "lidar_altitude": "lidar",
      "altitude_best_estimate": "best_estimate",
      "gps_altitude": "gps",
    };
    
    return propertyToAltitudeMapper[property] || "lidar";
  };

  const setAltitudeAndQuery = (altitude: AltitudeChannel) => {
    setAltitudeChannel(altitude);

    setQueryAltitudeChannel(
      mapPropertyToAltitude(altitude)
    );
  };

  const checkAltitude = useCallback(
    (type: AltitudeChannel, altitudeValues: any[]) => {
      const allNull = altitudeValues.every((value) => value === null);

      setDisableAltitudeFlags((prev) => ({
        ...prev,
        [type]: allNull,
      }));
    }, [setDisableAltitudeFlags]
  );

  const value: IMapContextProps = {
    primaryFluxplaneRunStatus,
    sceneView,
    surveyId,
    prevSurveyId,
    activeHeatmaps,
    prevActiveHeatmaps,
    removedActiveHeatmaps,
    addedActiveHeatmaps,
    activeFluxplaneRunStatus,
    fluxplaneRunStatuses,
    prevFluxplaneRunStatuses,
    removedFluxplaneRunStatuses,
    showUnquantifiedEmissions,
    features,
    prevFeatures,
    isEmissionsDiscreteColormap,
    isFlightpathByDiscreteColormap,
    isFlightpathByGradientColormap,
    isFlightpathByTrims,
    flightpathRendererSelection,
    selectedFluxplaneActionsId,
    mapFeatureInstances,
    areControlsVisible,
    mapListeners,
    lastEmissionChange,
    altitudeChannel,
    queryAltitudeChannel,
    disableAltitudeFlags,
    setPrimaryFluxplaneRunStatus,
    setSurveyId,
    setSceneView,
    setActiveHeatmaps,
    setActiveFluxplaneRunStatus,
    setFluxplaneRunStatuses,
    setShowUnquantifiedEmissions,
    setFeatures,
    setIsEmissionsDiscreteColormap,
    setIsFlightpathByDiscreteColormap,
    setIsFlightpathByGradientColormap,
    setIsFlightpathByTrims,
    setFlightpathRendererSelection,
    setSelectedFluxplaneActionsId,
    setMapFeatureInstances,
    setAreControlsVisible,
    setMapListeners,
    reset,
    isEmissionHeatmapCurrentlyActive,
    setLastEmissionChange,
    setAltitudeAndQuery,
    checkAltitude,
  };

  return <MapContext.Provider value={value}>{children}</MapContext.Provider>;
};

export default GISMapContextProvider;
