// Copyright 2021 SeekOps Inc.
import {
  FC,
  ReactNode,
  createContext,
  useContext,
  useState,
} from "react";

interface ITableContextProps {
  lastChanged: number;
  setLastChanged: (lastChanged: number) => void;
}

// Create a context
const TableContext = createContext<ITableContextProps | undefined>(undefined);

// Create a provider component
export const TableContextProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [lastChanged, setLastChanged] = useState<number>(Date.now());

  const value: ITableContextProps = {
    lastChanged,
    setLastChanged,
  };

  return (
    <TableContext.Provider value={value}>{children}</TableContext.Provider>
  );
};

// Create a custom hook to use the context
export const useTableContext = (): ITableContextProps => {
  const context = useContext(TableContext);
  if (!context) {
    throw new Error(
      "useTableContext must be used within a TableContextProvider"
    );
  }
  return context;
};
