// Copyright 2021 SeekOps Inc.
// third-party
import { Reducer } from "redux";

import QualityAssuranceActionTypes, {
  QualityAssuranceState,
} from "./qualityAssurance.types";

// Type-safe initialState!
export const initialQualityAssuranceState: QualityAssuranceState = {
  surveyIDForReview: -1,
  notes: "",
  isProcessingFinished: false,
  normalizeAltitude: true,
};

/**
 * set the abilities to whatever was in the local storage if needed.
 * Local storage is cleared whenever a log out occurs
 */
const getInitialPreferencesState = (): QualityAssuranceState => {
  let state: QualityAssuranceState = initialQualityAssuranceState;
  return state;
};

// Thanks to Redux 4's much simpler typings, we can take away a lot of typings
// on the reducer side, everything will remain type-safe.
const qualityAssuranceReducer: Reducer<QualityAssuranceState> = (
  state = getInitialPreferencesState(),
  action: any
) => {
  switch (action.type) {
    case QualityAssuranceActionTypes.SET_SURVEY_ID_FOR_REVIEW: {
      return { ...state, surveyIDForReview: action.surveyIDForReview };
    }
    case QualityAssuranceActionTypes.SET_NOTES: {
      return { ...state, notes: action.notes };
    }
    case QualityAssuranceActionTypes.SET_IS_PROCESSING_FINISHED: {
      return { ...state, isProcessingFinished: action.isProcessingFinished };
    }
    case QualityAssuranceActionTypes.SET_NORMALIZE_ALTITUDE: {
      return { ...state, normalizeAltitude: action.normalizeAltitude };
    }
    default:
      return state;
  }
};

// Instead of using default export, we use named exports. That way we can group
// these exports inside the `index.js` folder.
export default qualityAssuranceReducer;
