// Use const enums for better autocompletion of action type names. These will
// be compiled away leaving only the final value in your compiled code.
//
// Define whichever naming conventions you'd like for your action types, but
// personally, I use the `@@context/ACTION_TYPE` convention, to follow the

export enum notificationActionTypes {
  //Type Specific Action Types
  /**
   * Parameters:
   * @param origin
   * @param message
   * @param actionText
   */
  SET_SUCCESS_NOTIFICATION_OPEN = "@@notification/SET_SUCCESS_NOTIFICATION_OPEN",
  /**
   * Parameters:
   * @param origin
   * @param message
   * @param actionText
   */
  SET_WARNING_NOTIFICATION_OPEN = "@@notification/SET_WARNING_NOTIFICATION_OPEN",
  /**
   * Parameters:
   * @param origin
   * @param message
   * @param actionText
   */
  SET_ERROR_NOTIFICATION_OPEN = "@@notification/SET_ERROR_NOTIFICATION_OPEN",
  /**
   * Parameters:
   * @param origin
   * @param message
   * @param actionText
   */
  SET_INFO_NOTIFICATION_OPEN = "@@notification/SET_INFO_NOTIFICATION_OPEN",
  /**
   * Parameters:
   * @param origin
   * @param message
   * @param actionText
   */
  SET_DEFAULT_NOTIFICATION_OPEN = "@@notification/SET_DEFAULT_NOTIFICATION_OPEN",
  //**********************************/
  SET_NOTIFICATION_ACTION_TEXT = "@@notification/SET_NOTIFICATION_ACTION_TEXT",
  SET_NOTIFICATION_MESSAGE = "@@notification/SET_NOTIFICATION_MESSAGE",
  SET_NOTIFICATION_ORIGIN = "@@notification/SET_NOTIFICATION_ORIGIN",
  SET_NOTIFICATION_TYPE = "@@notification/SET_NOTIFICATION_TYPE",
  CLOSE_NOTIFICATION = "@@notification/CLOSE_NOTIFICATION",
  OPEN_NOTIFICATION = "@@notification/OPEN_NOTIFICATION",
  RESET = "@@notification/RESET",
}
export default notificationActionTypes;

export type NotificationAction = () => void;

// Declare state types with `readonly` modifier to get compile time immutability
// https://github.com/piotrwitek/react-redux-typescript-guide#state-with-type-level-immutability
export interface notificationState {
  isOpen: boolean;
  origin: string;
  message: string;
  actionText: string;
  actionCallback: undefined | ((args: any) => void | any);  
  notificationType: NotificationType;
}

/**
 * Declaration of Notification Banner Types.
 * Please do not modify, only add (Open-Close Principle)
 * #SOLID-Principles
 *
 * @export
 * @enum {number}
 */
export enum NotificationType {
  warning = "warning",
  info = "info",
  success = "success",
  error = "error",
  default = "default",
}
