// Copyright 2021 SeekOps Inc.
// third-party
import { Reducer } from "redux";

// first-party
import { projectState, projectActionTypes } from "./project.types";

// Type-safe initialState!
export const initialProjectState: projectState = {
  projectId: undefined,
  currentSiteId: undefined,
  surveyIds: undefined,
  currentSite: undefined,
};

// Thanks to Redux 4's much simpler typings, we can take away a lot of typings
// on the reducer side, everything will remain type-safe.
const projectReducer: Reducer<projectState> = (
  state = initialProjectState,
  action: any
) => {
  switch (action.type) {
    case projectActionTypes.SET_PROJECT_ID: {
      return { ...state, projectId: action.projectId };
    }
    case projectActionTypes.SET_CURRENT_SITE: {
      return { ...state, currentSite: action.currentSite };
    }
    case projectActionTypes.SET_PROJECT_SURVEY_IDS: {
      return { ...state, surveyIds: action.surveyIds };
    }
    case projectActionTypes.RESET: {
      return initialProjectState;
    }
    default:
      return state;
  }
};

// Instead of using default export, we use named exports. That way we can group
// these exports inside the `index.js` folder.
export default projectReducer;
