// Copyright 2021 SeekOps Inc.

import { IProjectSite } from "../../components/Project/ProjectSite/ProjectSite";

// Use const enums for better autocompletion of action type names. These will
// be compiled away leaving only the final value in your compiled code.
//
// Define whichever naming conventions you'd like for your action types, but
// personally, I use the `@@context/ACTION_TYPE` convention, to follow the

// convention of Redux's `@@INIT` action.
export enum projectActionTypes {
  SET_PROJECT_ID = "@@project/SET_PROJECT_ID",
  SET_CURRENT_SITE_ID = "@@project/SET_CURRENT_SITE_ID",
  SET_CURRENT_SITE = "@@project/SET_CURRENT_SITE",
  SET_PROJECT_SURVEY_IDS = "@@project/SET_SURVEY_IDS",
  RESET = "@@project/RESET",
}

// Declare state types with `readonly` modifier to get compile time immutability
// https://github.com/piotrwitek/react-redux-typescript-guide#state-with-type-level-immutability
export interface projectState {
  projectId: number | undefined;
  currentSiteId: number | undefined;
  surveyIds: number[] | undefined;
  currentSite: IProjectSite | undefined;
}

export default projectActionTypes;
