// Copyright 2021 SeekOps Inc.
// third-party
import { Reducer } from "redux";

// first-party
import { surveysState, surveysActionTypes } from "./surveys.types";

// Type-safe initialState!
export const initialSurveysState: surveysState = {
  isMoreActionsOpen: false,
  isBatchDialogOpen: false,
  isInfoOpen: false,
  infoContent: null,
  infoMap: {},
  surveysToMerge: [],
  batchDeleteIds: [],
  batchRestoreIds: [],
  currentSurveyId: null,
};

// Thanks to Redux 4's much simpler typings, we can take away a lot of typings
// on the reducer side, everything will remain type-safe.
const surveysReducer: Reducer<surveysState> = (
  state = initialSurveysState,
  action: any
) => {
  switch (action.type) {
    case surveysActionTypes.SET_INFO_CONTENT: {
      return { ...state, infoContent: action.infoContent };
    }
    case surveysActionTypes.SET_INFO_OPEN: {
      return { ...state, isInfoOpen: action.isInfoOpen };
    }
    case surveysActionTypes.SET_INFO_MAP: {
      return { ...state, infoMap: action.infoMap };
    }
    case surveysActionTypes.SET_SURVEYS_TO_MERGE: {
      return { ...state, surveysToMerge: action.surveysToMerge };
    }
    case surveysActionTypes.SET_IS_BATCH_DIALOG_OPEN: {
      return { ...state, isBatchDialogOpen: action.isBatchDialogOpen };
    }
    case surveysActionTypes.SET_BATCH_DELETE_IDS: {
      return { ...state, batchDeleteIds: action.batchDeleteIds };
    }
    case surveysActionTypes.SET_BATCH_RESTORE_IDS: {
      return { ...state, batchRestoreIds: action.batchRestoreIds };
    }
    case surveysActionTypes.SET_CURRENT_SURVEY_ID: {
      return { ...state, currentSurveyId: action.currentSurveyId };
    }
    // when toggle is the action, the given id is added to the set if it's not there and removed if it is
    case surveysActionTypes.TOGGLE_BATCH_DELETE_ID: {
      const batchDeleteIds: number[] = [...state.batchDeleteIds];
      const index = batchDeleteIds.indexOf(action.id);
      if (index > -1) {
        batchDeleteIds.splice(index, 1);
      } else {
        batchDeleteIds.push(action.id);
      }
      return { ...state, batchDeleteIds };
    }
    case surveysActionTypes.TOGGLE_BATCH_RESTORE_ID: {
      const batchRestoreIds: number[] = [...state.batchRestoreIds];
      const index = batchRestoreIds.indexOf(action.id);
      if (index > -1) {
        batchRestoreIds.splice(index, 1);
      } else {
        batchRestoreIds.push(action.id);
      }
      return { ...state, batchRestoreIds };
    }
    default:
      return state;
  }
};

// Instead of using default export, we use named exports. That way we can group
// these exports inside the `index.js` folder.
export default surveysReducer;
