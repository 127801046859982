// Copyright 2021 SeekOps Inc.
import { QAInfoContent } from "../../components/Surveys/Surveys.interfaces";

// Use const enums for better autocompletion of action type names. These will
// be compiled away leaving only the final value in your compiled code.
//
// Define whichever naming conventions you'd like for your action types, but
// personally, I use the `@@context/ACTION_TYPE` convention, to follow the

// convention of Redux's `@@INIT` action.
export enum surveysActionTypes {
  SET_INFO_OPEN = "@@surveys/SET_INFO_OPEN",
  SET_INFO_MAP = "@@surveys/SET_INFO_MAP",
  SET_INFO_CONTENT = "@@surveys/SET_INFO_CONTENT",
  SET_SURVEYS_TO_MERGE = "@@surveys/SET_SURVEYS_TO_MERGE",
  SET_IS_BATCH_DIALOG_OPEN = "@@surveys/SET_IS_BATCH_DIALOG_OPEN",
  SET_BATCH_DELETE_IDS = "@@surveys/SET_BATCH_DELETE_IDS",
  SET_BATCH_RESTORE_IDS = "@@surveys/SET_BATCH_RESTORE_IDS",
  SET_CURRENT_SURVEY_ID = "@@surveys/SET_CURRENT_SURVEY_ID",
  TOGGLE_BATCH_DELETE_ID = "@@surveys/TOGGLE_BATCH_DELETE_ID",
  TOGGLE_BATCH_RESTORE_ID = "@@surveys/TOGGLE_BATCH_RESTORE_ID",
}

// Declare state types with `readonly` modifier to get compile time immutability
// https://github.com/piotrwitek/react-redux-typescript-guide#state-with-type-level-immutability
export interface surveysState {
  isMoreActionsOpen: boolean;
  isInfoOpen: boolean;
  infoMap: { [key: string]: string };
  infoContent: QAInfoContent | null;
  surveysToMerge: number[];
  isBatchDialogOpen: boolean;
  batchDeleteIds: number[];
  batchRestoreIds: number[];
  currentSurveyId: number | null;
}

export default surveysActionTypes;
