// Copyright 2021 SeekOps Inc.
// third-party
import { Reducer } from "redux";

// first-party
import {
  WindroseState,
  windroseActionTypes
} from "./windrose.types";

// Type-safe initialState!
export const initialState: WindroseState = {
  heading: 0,
  loading: false,
  error: null
};

// Thanks to Redux 4's much simpler typings, we can take away a lot of typings 
// on the reducer side, everything will remain type-safe.
const windroseReducer: Reducer<WindroseState> = (
  state = initialState,
  action: any
) => {
  switch (action.type) {
    case windroseActionTypes.SET_HEADING: {
      return { ...state, heading: action.heading };
    }
    default:
      return state;
  }
};

// Instead of using default export, we use named exports. That way we can group 
// these exports inside the `index.js` folder.
export default windroseReducer;
