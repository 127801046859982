// Copyright 2021 SeekOps Inc.
import i18next from "i18next";
import i18n from "i18next";

import { initReactI18next } from "react-i18next";


export interface Language {
  label: string;
  languageCode: string;
  name: string;
}

export enum LanguageCode {
  ENUS = "en-US",
  ARSA = "ar-SA",
  ZHCN = "zh-CN",
  ESMX = "es-MX",
  PTBR = "pt-BR",
  RURU = "ru-RU",
  FRFR = "fr-FR",
  ITIT = "it-IT"
}

export enum Locale {
  "en-US" = "en-US",
  "ar-SA" = "ar-SA",
  "zh-CN" = "zh-CN",
  "es-MX" = "es-MX",
  "pt-BR" = "pt-BR",
  "fr-FR" = "fr-FR",
  "it-IT" = "it-IT",
  "ru-RU" = "ru-RU",
}

// mapping of locale codes to language direction (ltr or rtl)
// explictly set the type of the map map with keys as strings and values as strings
export const languageDirMap: { [key: string]: string } = {
  "en-US": "ltr",
  "ar-SA": "rtl",
  "zh-CN": "ltr",
  "es-MX": "ltr",
  "pt-BR": "ltr",
  "fr-FR": "ltr",
  "it-IT": "ltr",
  "ru-RU": "ltr",
};

export const loadTranslations = (config : { code: LanguageCode, file: any }[]) => {
  for(let language of Object.values(LanguageCode)) {
    const translation = config.find((c) => c.code === language)?.file;
    i18next.addResourceBundle(language, "translation", translation);
  }
}

export const supportedLanguages = [
  // English USA
  {
    label: "English",
    languageCode: LanguageCode.ENUS,
    name: "English",
  },
  // Arabic Saudi Arabia
  {
    label: "عربى",
    languageCode: LanguageCode.ARSA,
    name: "Arabic",
  },
  // Chinese China
  {
    label: "简体中文",
    languageCode: LanguageCode.ZHCN,
    name: "Chinese",
  },
  // Spanish Mexico
  {
    label: "Español",
    languageCode: LanguageCode.ESMX,
    name: "Spanish",
  },
  // Portuguese Brazil
  {
    label: "Português",
    languageCode: LanguageCode.PTBR,
    name: "Portuguese",
  },
  // Russian Russia
  {
    label: "русский",
    languageCode: LanguageCode.RURU,
    name: "Russian",
  },
  // French France
  {
    label: "français",
    languageCode: LanguageCode.FRFR,
    name: "French",
  },
  // Italian Italy
  {
    label: "Italiano",
    languageCode: LanguageCode.ITIT,
    name: "Italian",
  },
];

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    debug: false,
    lng: LanguageCode.ENUS, // get the language/locale from the store
    fallbackLng: LanguageCode.ENUS, // fall back to English USA
    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
