// Copyright 2021 SeekOps Inc.

import { createContext, useContext } from "react";

import SceneView from "@arcgis/core/views/SceneView";
import { FluxplaneRunStatus } from "../../FluxplaneRun/FluxplaneRun.interfaces";
import { GISMapFeaturesToggles } from "..//GISMap.interfaces";
import GISMapFeature from "../GISMapFeatures/GISMapFeature";
import { ActiveHeatMap } from "../../FluxplaneRun/FluxplaneRunEmissions/FluxplaneRunEmissions";

export interface IEmissionChange {
  timestamp: string;
  idOfEmissionChanged?: number;
  idOfParentFPR: number;
}
export type TRendererSelection = "discrete" | "gradient" | "trims";

export type AltitudeChannel = "lidar_altitude" | "altitude_best_estimate" | "gps_altitude";

export type AltitudesState = {
  [key in AltitudeChannel]: boolean;
}

export interface IMapContextProps {
  sceneView: SceneView | null;
  surveyId: number | null;
  prevSurveyId: number | null;
  activeHeatmaps: ActiveHeatMap[];
  prevActiveHeatmaps: ActiveHeatMap[];
  removedActiveHeatmaps: ActiveHeatMap[];
  addedActiveHeatmaps: ActiveHeatMap[];
  activeFluxplaneRunStatus: FluxplaneRunStatus | null;
  fluxplaneRunStatuses: FluxplaneRunStatus[];
  prevFluxplaneRunStatuses: FluxplaneRunStatus[];
  removedFluxplaneRunStatuses: FluxplaneRunStatus[];
  primaryFluxplaneRunStatus: FluxplaneRunStatus | null;
  showUnquantifiedEmissions: boolean;
  features: GISMapFeaturesToggles | null;
  prevFeatures: GISMapFeaturesToggles | null;
  isEmissionsDiscreteColormap: boolean;
  isFlightpathByDiscreteColormap: boolean;
  isFlightpathByGradientColormap: boolean;
  isFlightpathByTrims: boolean;
  flightpathRendererSelection: TRendererSelection;
  selectedFluxplaneActionsId: number | null;
  mapFeatureInstances: GISMapFeature[];
  areControlsVisible: boolean;
  mapListeners: IHandle[];
  lastEmissionChange: IEmissionChange | null;
  altitudeChannel: AltitudeChannel;
  queryAltitudeChannel: string;
  disableAltitudeFlags: AltitudesState;
  setSurveyId: (surveyId: number | null) => void;
  setSceneView: (view: SceneView | null) => void;
  setActiveHeatmaps: (heatmaps: any[]) => void;
  setActiveFluxplaneRunStatus: (status: FluxplaneRunStatus) => void;
  setFluxplaneRunStatuses: (statuses: any[]) => void;
  setPrimaryFluxplaneRunStatus: (
    primaryFluxplaneRunSatus: FluxplaneRunStatus | null
  ) => void;
  setShowUnquantifiedEmissions: (showUnquantifiedEmissions: boolean) => void;
  setFeatures: (features: GISMapFeaturesToggles | null) => void;
  setIsEmissionsDiscreteColormap: (
    isEmissionsDiscreteColormap: boolean
  ) => void;
  setIsFlightpathByDiscreteColormap: (
    isFlightpathByDiscreteColormap: boolean
  ) => void;
  setIsFlightpathByGradientColormap: (
    isFlightpathByGradientColormap: boolean
  ) => void;
  setIsFlightpathByTrims: (isFlightpathByTrims: boolean) => void;
  setFlightpathRendererSelection: (
    flightpathRendererSelection: TRendererSelection
  ) => void;
  setSelectedFluxplaneActionsId: (
    selectedFluxplaneActionsId: number | null
  ) => void;
  setMapFeatureInstances: (mapFeatureInstances: GISMapFeature[]) => void;
  setAreControlsVisible: (areControlsVisible: boolean) => void;
  setMapListeners: (mapListeners: IHandle[]) => void;
  reset: () => void;
  isEmissionHeatmapCurrentlyActive: (emissionId: number) => boolean;
  setLastEmissionChange: (lastEmissionChange: IEmissionChange | null) => void;
  setAltitudeAndQuery: (altitude: AltitudeChannel) => void;
  checkAltitude: (type: AltitudeChannel, altitudeValues: any[]) => void;
}

export const MapContext = createContext<IMapContextProps | undefined>(
  undefined
);

export const useGISMapContext = (): IMapContextProps => {
  const context = useContext(MapContext);
  if (!context) {
    throw new Error("useMapContext must be used within a MapContextProvider");
  }
  return context;
};
