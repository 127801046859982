// Copyright 2021 SeekOps Inc.
// third-party

// first-party
import { AuthorizationState, authorizationTypes } from "./authorization.types";
import { getLocalStorageItem, setLocalStorageItem } from "../../utils/LocalStorage.utils";

/**
 * set the abilities to whatever was in the local storage. Local storage
 * is cleared whenever a log out occurs or a token refresh fails
 */
const getInitialAuthorizationState = (): AuthorizationState => {
  let state: AuthorizationState = { abilities: {} };
  // check if local storage has languageSelectorState
  let existingAbilitiesString = getLocalStorageItem("abilities");
  if (existingAbilitiesString) {
    let existingAuthorizationState: AuthorizationState = JSON.parse(
      existingAbilitiesString
    );
    if (existingAuthorizationState) {
      state = { abilities: { ...existingAuthorizationState } };
    }
  }
  return state;
};

// Type-safe initialState!
export const initialState: AuthorizationState = getInitialAuthorizationState();

// Thanks to Redux 4's much simpler typings, we can take away a lot of typings on the reducer side,
// everything will remain type-safe.
const authorizationReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case authorizationTypes.SET_ABILITIES: {
      setLocalStorageItem("abilities", JSON.stringify(action.abilities));
      return { ...state, abilities: action.abilities };
    }
    default: {
      return state;
    }
  }
};

// Instead of using default export, we use named exports. That way we can group these exports
// inside the `index.js` folder.
export default authorizationReducer;
