// Copyright 2021 SeekOps Inc.
// third-party
import { Reducer } from "redux";

// first-party
import { preferencesState, preferencesActionTypes } from "./preferences.types";
import { getLocalStorageItem, setLocalStorageItem } from "../../utils/LocalStorage.utils";

// Type-safe initialState!
export const initialPreferencesState: preferencesState = {
  emissionsPreferences: {
    unit: "ppm",
    stops: {
      low: 9,
      medium: 9,
      high: 9,
    },
    colors: {
      low: "green",
      medium: "yellow",
      high: "red",
    },
  },
  flightpathPreferences: {
    unit: "ppm",
    stops: {
      low: 9,
      medium: 9,
      high: 9,
    },
    colors: {
      low: "green",
      medium: "yellow",
      high: "red",
    },
  },
  userUnits: {
    flowRateUnit: "t/yr",
    systemUnit: "metric",
  },
  isEmissionsDiscreteColormap: true,
  isFlightpathDiscreteColormap: false,
  showUnquantifiedEmissions: true,
  surveyPreferences: {
    showDeleted: false,
    showRejected: false,
    enableMerging: false,
  },
};

/**
 * set the abilities to whatever was in the local storage. Local storage
 * is cleared whenever a log out occurs or a token refresh fails
 */
const getInitialPreferencesState = (): preferencesState => {
  let state: preferencesState = initialPreferencesState;
  // check if local storage has languageSelectorState
  let existingEmissionsPreferencesString = getLocalStorageItem(
    "emissions_preferences"
  );
  if (existingEmissionsPreferencesString) {
    state.emissionsPreferences = JSON.parse(existingEmissionsPreferencesString);
  }

  let existingFlightpathPreferencesString = getLocalStorageItem(
    "flightpath_preferences"
  );
  if (existingFlightpathPreferencesString) {
    state.flightpathPreferences = JSON.parse(
      existingFlightpathPreferencesString
    );
  }

  let existingUserUnitsString = getLocalStorageItem("user_units");
  if (existingUserUnitsString) {
    state.userUnits = JSON.parse(existingUserUnitsString);
  } else {
    setLocalStorageItem("user_units", JSON.stringify(state.userUnits));
  }
  return state;
};

// Thanks to Redux 4's much simpler typings, we can take away a lot of typings
// on the reducer side, everything will remain type-safe.
const preferencesReducer: Reducer<preferencesState> = (
  state = getInitialPreferencesState(),
  action: any
) => {
  switch (action.type) {
    case preferencesActionTypes.SET_EMISSIONS_PREFERENCES: {
      setLocalStorageItem(
        "emissions_preferences",
        JSON.stringify(action.emissionsPreferences)
      );
      return { ...state, emissionsPreferences: action.emissionsPreferences };
    }
    case preferencesActionTypes.SET_FLIGHTPATH_PREFERENCES: {
      setLocalStorageItem(
        "flightpath_preferences",
        JSON.stringify(action.flightpathPreferences)
      );
      return { ...state, flightpathPreferences: action.flightpathPreferences };
    }
    case preferencesActionTypes.SET_IS_EMISSIONS_DISCRETE_COLORMAP: {
      return {
        ...state,
        isEmissionsDiscreteColormap: action.isEmissionsDiscreteColorMap,
      };
    }
    case preferencesActionTypes.SET_IS_FLIGHTPATH_DISCRETE_COLORMAP: {
      return {
        ...state,
        isFlightpathDiscreteColormap: action.isFlightpathDiscreteColorMap,
      };
    }
    case preferencesActionTypes.SET_SHOW_UNQUANTIFIED_EMISSIONS: {
      return {
        ...state,
        showUnquantifiedEmissions: action.showUnquantifiedEmissions,
      };
    }
    case preferencesActionTypes.SET_USER_UNITS: {
      setLocalStorageItem("user_units", JSON.stringify(action.userUnits));
      return {
        ...state,
        userUnits: action.userUnits,
      };
    }
    case preferencesActionTypes.SET_SURVEYS_SHOW_DELETED: {
      return {
        ...state,
        surveyPreferences: {
          ...state.surveyPreferences,
          showDeleted: action.showDeleted,
        },
      };
    }
    case preferencesActionTypes.SET_SURVEYS_SHOW_REJECTED: {
      return {
        ...state,
        surveyPreferences: {
          ...state.surveyPreferences,
          showRejected: action.showRejected,
        },
      };
    }
    case preferencesActionTypes.SET_SURVEYS_ENABLE_MERGING: {
      return {
        ...state,
        surveyPreferences: {
          ...state.surveyPreferences,
          enableMerging: action.enableMerging,
        },
      };
    }
    default:
      return state;
  }
};

// Instead of using default export, we use named exports. That way we can group
// these exports inside the `index.js` folder.
export default preferencesReducer;
