// Copyright 2021 SeekOps Inc.
// third-party
import { Reducer } from "redux";

// first-party
import {
  IPremiumTableState,
  premiumTableActionTypes,
} from "./premiumTable.types";

// Type-safe initialState
// make inital state for premium table
export const initialPremiumTableState: IPremiumTableState = {
  rows: [],
  columns: [],
  tableActions: [],
  searchModel: undefined,
  sortModel: [],
  paginationModel: undefined,
  filterModel: undefined,
  mandatoryFilterFields: [],
  dataEndpoint: "",
  isLoading: false,
  actionId: null,
  currentAction: null,
};

// Thanks to Redux 4's much simpler typings, we can take away a lot of typings
// on the reducer side, everything will remain type-safe.
const premiumTableReducer: Reducer<IPremiumTableState> = (
  state = initialPremiumTableState,
  action: any
) => {
  switch (action.type) {
    case premiumTableActionTypes.SET_ROWS: {
      return { ...state, rows: action.rows };
    }
    case premiumTableActionTypes.SET_COLUMNS: {
      return { ...state, columns: action.columns };
    }
    case premiumTableActionTypes.SET_TABLE_ACTIONS: {
      return { ...state, tableActions: action.tableActions };
    }
    case premiumTableActionTypes.SET_SEARCH_MODEL: {
      return { ...state, searchModel: action.searchModel };
    }
    case premiumTableActionTypes.SET_SORT_MODEL: {
      return { ...state, sortModel: action.sortModel };
    }
    case premiumTableActionTypes.SET_PAGINATION_MODEL: {
      return { ...state, paginationModel: action.paginationModel };
    }
    case premiumTableActionTypes.SET_FILTER_MODEL: {
      return { ...state, filterModel: action.filterModel };
    }
    case premiumTableActionTypes.SET_MANDATORY_FILTER_FIELDS: {
      return { ...state, mandatoryFilterFields: action.mandatoryFilterFields };
    }
    case premiumTableActionTypes.SET_DATA_ENDPOINT: {
      return { ...state, dataEndpoint: action.newDataEndpoint };
    }
    case premiumTableActionTypes.APPLY_IS_LOADING: {
      return { ...state, isLoading: action.isLoading };
    }
    case premiumTableActionTypes.SET_ACTION_ID: {
      return { ...state, actionId: action.actionId };
    }
    case premiumTableActionTypes.SET_CURRENT_ACTION: {
      return { ...state, currentAction: action.currentAction };
    }
    default:
      return state;
  }
};

// Instead of using default export, we use named exports. That way we can group
// these exports inside the `index.js` folder.
export default premiumTableReducer;
