// Copyright 2021 SeekOps Inc.
// third-party
import { merge } from "lodash/fp";

// first-party
import getColorPalette from "./theme.colors";
import { ColorPaletteName, ColorName } from "./theme.enums";
import { CustomThemeOptions } from "./theme.interfaces";
import themeDefaultOverrides from "./theme.default-overrides";

/**
 * array of colors to be used by charts (in this order)
 */
export const chartsLightPalette = [
  //Colors chosen using Paletton.com
  //Using Falu Red #7e1516 (SeekOps Red)
  //https://paletton.com/

  "#157e48", //Jewel
  "#ffc039", //Sunglow
  "#7e1516", //Falu Red
  "#154b7e", //Cathams Blue
  "#E86900", //Clementine
  "#CC5959", //Chestnut Rose
  "#002D00", //Deep Fir
  "#651140", //Claret
  "#012731", //Daintree
  "#7e4515", //Copper Canyon
];

const ThemeConfigLight: CustomThemeOptions = {
  chartPalette: chartsLightPalette,
  direction: "ltr",
  palette: {
    mode: "light",
    primary: getColorPalette(ColorPaletteName.galaxygray),
    secondary: getColorPalette(ColorPaletteName.oxbloodred),
    error: getColorPalette(ColorPaletteName.oxbloodred),
  },
  components: {
    MuiTab: {
      styleOverrides: {
        root: {
          color: ColorName.seekopsBlack,
          fill: ColorName.seekopsBlack,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          color: ColorName.seekopsBlack,
          "&.Mui-disabled": {
            svg: {
              fill: "rgba(0, 0, 0, 0.26)",
            },
          },
        },
        contained: {
          backgroundColor: ColorName.seekopsGreen,
          color: ColorName.seekopsWhite,
          fill: ColorName.seekopsWhite,
        },
        containedPrimary: {
          backgroundColor: ColorName.seekopsInfoBlue,
          color: ColorName.seekopsWhite,
          fill: ColorName.seekopsWhite,
        },
        containedSecondary: {
          backgroundColor: ColorName.seekopsRed,
          color: ColorName.seekopsWhite,
          fill: ColorName.seekopsWhite,
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: ColorName.seekopsBlack,
          fill: ColorName.seekopsBlack,
        },
      },
    },
    MuiFab: {
      styleOverrides: {
        primary: {
          backgroundColor: ColorName.seekopsGreen,
        },
        root: {
          backgroundColor: ColorName.seekopsInfoBlue,
          color: ColorName.seekopsWhite,
          fill: ColorName.seekopsWhite,
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          color: ColorName.seekopsBlack,
          fill: ColorName.seekopsBlack,
          "&.table-actions": {
            color: ColorName.seekopsBlack,
            fill: ColorName.seekopsBlack,
          },
        },
        head: {
          color: ColorName.seekopsInfoBlue,
          fill: ColorName.seekopsInfoBlue,
        },
        body: {
          color: ColorName.seekopsBlack + " !important",
          fill: ColorName.seekopsBlack + " !important",
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          color: ColorName.seekopsBlack,
          fill: ColorName.seekopsBlack,
        },
        caption: {
          color: ColorName.seekopsInfoBlue,
        },
        body1: {
          color: "#545353 !important",
        },
      },
    },
    MuiNativeSelect: {
      styleOverrides: {
        select: {
          option: {
            backgroundColor: "#cccccc !important",
            color: ColorName.seekopsBlack,
          },
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        select: {
          color: ColorName.seekopsInfoBlue,
        },
        selectIcon: {
          fill: ColorName.seekopsInfoBlue,
          color: ColorName.seekopsInfoBlue,
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: ColorName.seekopsBlack,
          fill: ColorName.seekopsBlack,
        },
      },
    },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          "&.Mui-active": {
            fill: "#154b7e",
          },
          "&.Mui-completed": {
            fill: "#157e48",
          },
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          backgroundColor: "#F4F4F4",
        },
      },
    },
    MuiInputAdornment: {
      styleOverrides: {
        root: {
          color: "#545353",
          fill: "#545353",
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        popper: {
          backgroundColor: "rgba(255, 255, 255, 0.3) !important",
        },
      },
    },
  },
};

export default merge(themeDefaultOverrides, ThemeConfigLight);
