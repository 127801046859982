// Copyright 2021 SeekOps Inc.
// third-party
import { Reducer } from "redux";

// first-party
import { sideBarState, sideBarActionTypes } from "./sideBar.types";

// Type-safe initialState!
export const initialSideBarState: sideBarState = {
  isOpen: false,
};

// Thanks to Redux 4's much simpler typings, we can take away a lot of typings
// on the reducer side, everything will remain type-safe.
const sideBarReducer: Reducer<sideBarState> = (
  state = initialSideBarState,
  action: any
) => {
  switch (action.type) {
    case sideBarActionTypes.SET_IS_OPEN: {
      return { ...state, isOpen: action.isOpen };
    }
    default:
      return state;
  }
};

// Instead of using default export, we use named exports. That way we can group
// these exports inside the `index.js` folder.
export default sideBarReducer;
