// Copyright 2021 SeekOps Inc.
// Use const enums for better autocompletion of action type names. These will
// be compiled away leaving only the final value in your compiled code.
//
// Define whichever naming conventions you'd like for your action types, but
// personally, I use the `@@context/ACTION_TYPE` convention, to follow the

import { FlowRateUnit, SystemUnit } from "../../components/UserUnits/UserUnits";

// convention of Redux's `@@INIT` action.
export enum preferencesActionTypes {
  SET_EMISSIONS_PREFERENCES = "@@preferences/SET_EMISSIONS_PREFERENCES",
  SET_FLIGHTPATH_PREFERENCES = "@@preferences/SET_FLIGHTPATH_PREFERENCES",
  SET_IS_EMISSIONS_DISCRETE_COLORMAP = "@@preferences/SET_IS_EMISSIONS_DISCRETE_COLORMAP",
  SET_IS_FLIGHTPATH_DISCRETE_COLORMAP = "@@preferences/SET_IS_FLIGHTPATH_DISCRETE_COLORMAP",
  SET_SHOW_UNQUANTIFIED_EMISSIONS = "@@preferences/SET_SHOW_UNQUANTIFIED_EMISSIONS",
  SET_USER_UNITS = "@@preferences/SET_USER_UNITS",
  SET_SURVEYS_SHOW_DELETED = "@@preferences/SET_SURVEYS_SHOW_DELETED",
  SET_SURVEYS_SHOW_REJECTED = "@@preferences/SET_SURVEYS_SHOW_REJECTED",
  SET_SURVEYS_ENABLE_MERGING = "@@preferences/SET_SURVEYS_ENABLE_MERGING",
}

// Declare state types with `readonly` modifier to get compile time immutability
// https://github.com/piotrwitek/react-redux-typescript-guide#state-with-type-level-immutability
export interface preferencesState {
  emissionsPreferences: emissionsPreferences;
  flightpathPreferences: flightpathPreferences;
  isEmissionsDiscreteColormap: boolean;
  isFlightpathDiscreteColormap: boolean;
  showUnquantifiedEmissions: boolean;
  userUnits: UserUnitsITF;
  surveyPreferences: SurveyPreferences;
}

interface severityColorsPreferences {
  low: string;
  medium: string;
  high: string;
  unquantified?: string;
}

interface severityStopsPreferences {
  low: number;
  medium: number;
  high: number;
  unquantified?: number;
}

export interface emissionsPreferences {
  colors: severityColorsPreferences;
  stops: severityStopsPreferences;
  unit: string;
}

export interface flightpathPreferences {
  colors: severityColorsPreferences;
  stops: severityStopsPreferences;
  unit: string;
}

export interface UserUnitsITF {
  systemUnit: SystemUnit;
  flowRateUnit: FlowRateUnit;
}

export interface SurveyPreferences {
  showDeleted: boolean;
  showRejected: boolean;
  enableMerging: boolean;
}

export default preferencesActionTypes;
