// Copyright 2021 SeekOps Inc.
import { getBaseURL } from "./global";
import axios, { AxiosInstance } from "axios";
import {
  clearLocalStorageNamespaceByKeys,
  getLocalStorageItem,
  setLocalStorageItem
} from "./utils/LocalStorage.utils";

// any urls that should not have the token added can be added to this collection
const TOKEN_EXEMPT_URLS = [
  "seekopstest.file.core.windows.net",
  "seekopsdev.file.core.windows.net",
  "seekopsprod.file.core.windows.net",
  "seekopsscience.file.core.windows.net",
];

const getCurrentAccessToken = (): string => {
  let access_token: string | null = getJWT();
  return access_token + ""; // ensure string is returned
};

// Global funcion pointer to prevent multiple refresh token happening at the same time.
let refreshingFunc: Promise<string | void> | undefined = undefined;

/**
 *
 * @return headers object to include with a request
 */
const getHeaders = (): object => {
  let headers: any = {};
  let token = getJWT();

  if (token) {
    // add JWT if it exists
    headers.Authorization = "Bearer " + token;
  }
  return headers;
};

/**
 * set web token in local storage/store
 *
 * @param JWT - web token to be set
 */
export const setJWT = async (JWT: string) => {
  if (localStorage) {
    setLocalStorageItem("JWT", JWT);
  }
};

/**
 * Returns the current access token
 *
 * @returns the current access token
 */
export const getJWT = (): string | null => {
  let JWT = "";
  try {
    if (localStorage) {
      JWT = getLocalStorageItem("JWT") || "";
    }
  } catch (error: any) {
    console.error("getJWT | ", error);
  }
  return JWT;
};

/*
 * set refresh token in local storage/store
 *
 * @param JWTr - web token to be set
 */
export const setJWTr = (JWTr: string) => {
  if (localStorage) {
    setLocalStorageItem("JWTr", JWTr);
  }
};

/**
 * Returns the current refresh token
 *
 * @returns the current refresh token
 *
 */
export const getJWTr = (): string | null => {
  let JWTr = "";
  try {
    if (localStorage) {
      JWTr = getLocalStorageItem("JWTr") || "";
    }
  } catch (error: any) {
    console.error("getJWTr | ", error);
  }
  return JWTr;
};

/**
 * Refreshes the access token using the refresh token.
 * @returns The new access token.
 * @throws Error if the refresh token is missing or the refresh fails.
 */
async function tokenRefresh(): Promise<string | undefined> {
  try {
    const refresh_token: string | null = getJWTr();
    const access_token = getCurrentAccessToken();

    if (!refresh_token) {
      throw new Error("Refresh token does not exist");
    }

    const response = await axiosInstance.post("/token/refresh/", {
      access: access_token,
      refresh: refresh_token,
    });

    const new_access_token = response.data.access;

    return new_access_token;
  } catch (error: any) {
    console.error("Error getting new access token: ", error);
  }
}

/**
 * Updates the authentication token and retries failed requests.
 * If the authentication token is refreshed, it updates the Axios instance headers as well.
 * Handles errors and cleans up the refresh function.
 * @returns A Promise that resolves to the new authentication token or void if there was an error.
 */
async function updateAuthToken(): Promise<string | void> {
  try {
    // If there's no ongoing token refresh, initiate one
    if (!refreshingFunc) refreshingFunc = tokenRefresh();

    // Wait for the token refresh to complete
    const new_access_token = await refreshingFunc;

    // If a new token was obtained, update the JWT and Axios headers
    if (new_access_token) {
      setJWT(new_access_token);
    }

    const authHeader = `Bearer ${new_access_token}`;

    axiosInstance.defaults.headers.common["Authorization"] = authHeader;

    return authHeader;
  } catch (error: any) {
    console.error("TOKEN REFRESH ERROR: ", error);
  } finally {
    // Clean up the refresh function
    refreshingFunc = undefined;
  }
}

// function isURLExemptFromToken(URL: string, string[]): boolean
// returns a boolean of true if the given string is contained in the given array; false otherwise
const isURLExemptFromToken = (URL: string, exemptURLs: string[]): boolean => {
  for (let i = 0; i < exemptURLs.length; i++) {
    if (URL.indexOf(exemptURLs[i]) !== -1) {
      return true;
    }
  }
  return false;
};

/**
 * Creates an instance of Axios for API use.
 * @param baseURL - The base URL for the API.
 * @param headers - Custom headers to include in the requests.
 * @returns An AxiosInstance for making API requests.
 */
const getAxiosInstance = (baseURL: string, headers: any): AxiosInstance => {
  // Create a new Axios instance with provided base URL and headers
  let axiosInstance = axios.create({
    baseURL: baseURL,
    headers: headers,
  });

  axiosInstance.interceptors.response.use(
    (response) => response,

    async (error: any) => {
      const originalRequest = error.config;

      // If the error is due to unauthorized access and it's not a retry
      if (
        error.response &&
        error.response.status === 401 &&
        !originalRequest._retry
      ) {
        originalRequest._retry = true;

        // Attempt to update the authentication token
        const authHeader = await updateAuthToken();

        // Update the request headers with the new token and retry the request
        originalRequest.headers["Authorization"] = authHeader;
        return axiosInstance(originalRequest);
      }

      return Promise.reject(error);
    }
  );

  // Add a request interceptor
  axiosInstance.interceptors.request.use(
    (config) => {
      // set the config headers Authorization token to what is in storage
      const authToken = getCurrentAccessToken();
      const URL = config.url;

      if (URL) {
        // if the URL is not in the exempt list, do not add the token; otherwise add the token
        if (isURLExemptFromToken(URL, TOKEN_EXEMPT_URLS)) {
          config.headers.delete("Authorization");
        } else {
          config.headers["Authorization"] = `Bearer ${authToken}`;

          // check if there are any params
          let tokens = URL.split("?");
          if (tokens.length === 1) {
            // when no params, end with slash
            if (
              !URL.endsWith("/") &&
              !URL.endsWith(".xml") &&
              !URL.endsWith(".png") &&
              !URL.endsWith(".jpg") &&
              !URL.endsWith(".JPG")
            )
              config.url += "/";
          } else {
            // when params, make sure there is no slash
            config.url = tokens[0].replace(/\/$/, "") + "/?" + tokens[1];
          }
        }
      }
      // Do something before request is sent
      return config;
    },
    (error) => {
      // Do something with request error
      return Promise.reject(error);
    }
  );

  return axiosInstance;
};

/**
 *
 */
const axiosInstance: AxiosInstance = getAxiosInstance(
  getBaseURL(),
  getHeaders()
);

// Instantiate the interceptor (you can chain it as it returns the axios instance)
// createAuthRefreshInterceptor(axiosInstance, refreshAuthLogic);

/**
 *
 */
const destroyTokens = () => {
  clearLocalStorageNamespaceByKeys(["JWT", "JWTr"]);
};

/**
 *
 * @param axiosInstance - the instance of axios for which to refresh token
 * @param JWT
 * @param JWTr
 */
export const refreshToken = (
  axiosInstance: AxiosInstance,
  JWT?: string,
  JWTr?: string
): Promise<boolean> => {
  let storedToken = getJWTr();
  let storedRefreshToken = getJWTr();

  // in case no arguments are provided
  if (!JWT && storedToken) {
    JWT = storedToken;
  }

  if (!JWTr && storedRefreshToken) {
    JWTr = storedRefreshToken;
  }

  //let wasSuccessfullyRefreshed = false;
  return axiosInstance
    .post("/token/refresh/", { access: JWT, refresh: JWTr })
    .then(({ data }) => {
      if (data) {
        // successfully refreshed so store the new token
        setJWT(data.access);
        // update axios instance header
        axiosInstance.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${data.access}`;
        //wasSuccessfullyRefreshed = true;
      }
      return data.access;
    })
    .catch((err) => {
      // refresh failed so remove expired tokens
      destroyTokens();
      return err;
    });
};

/**
 *
 * @param axiosInstance - the instance of axios for which to refresh token
 * @param JWT
 * @param JWTr
 */
export const awaitRefreshToken = async (
  axiosInstance: AxiosInstance,
  JWT?: string,
  JWTr?: string
) => {
  let storedToken = getJWT();
  let storedRefreshToken = getJWTr();

  // in case no arguments are provided
  if (!JWT && storedToken) {
    JWT = storedToken;
  }

  if (!JWTr && storedRefreshToken) {
    JWTr = storedRefreshToken;
  }

  //let wasSuccessfullyRefreshed = false;
  let response = await axiosInstance.post("/token/refresh/", {
    access: JWT,
    refresh: JWTr,
  });

  if (response.status !== 200) {
    destroyTokens();
    throw new Error(`HTTP error! status: ${response.status}`);
  } else {
  }
  const data = await response.data;

  if (data) {
    // successfully refreshed so store the new token
    setJWT(data.access);
    // update axios instance header
    axiosInstance.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${data.access}`;
    //wasSuccessfullyRefreshed = true;
    return data.access;
  }
  return "";
};

export default axiosInstance;
