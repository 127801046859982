// Copyright 2021 SeekOps Inc.
/**
 * define Material UI themes
 */

// third-party
import { createTheme } from "@mui/material/styles";

// first-party
import { CustomTheme, CustomThemeOptions } from "./theme.interfaces";
import ThemeConfigDark, { chartsDarkPalette } from "./theme.dark";
import ThemeConfigLight, { chartsLightPalette } from "./theme.light";

export enum ThemeType {
  dark = "dark",
  light = "light",
  seekops = "seekops",
  brutal = "brutal",
}

export const viridisPalette = [
  "rgba(68, 1, 84, 1)",
  "rgba(72, 40, 120, 1)",
  "rgba(58, 82, 139, 1)",
  "rgba(43, 115, 142, 1)",
  "rgba(33, 144, 141, 1)",
  "rgba(32, 144, 140, 1)",
  "rgba(50, 175, 120, 1)",
  "rgba(78, 200, 100, 1)",
  "rgba(128, 222, 100, 1)",
  "rgba(190, 234, 80, 1)",
  "rgba(253, 231, 37, 1)",
];

/**
 *
 * @param themeType
 */
const getTheme = (themeType: string): CustomTheme => {
  let themeConfig: CustomThemeOptions;
  let chartPalette: string[];

  if (themeType === "light") {
    themeConfig = ThemeConfigLight;
    chartPalette = chartsLightPalette;
  } else {
    themeConfig = ThemeConfigDark;
    chartPalette = chartsDarkPalette;
  }

  const themeInstance = createTheme({ ...themeConfig });
  const customThemeInstance: CustomTheme = {
    ...themeInstance,
    chartPalette: chartPalette,
    viridisPalette: viridisPalette,
  };
  return customThemeInstance;
};

export default getTheme;
