// Copyright 2021 SeekOps Inc.
// first-party
import { ColorName } from "./theme.enums";
import { CustomThemeOptions } from "./theme.interfaces";

/**
 * Use the fullscreen element if in fullscreen mode,
 * otherwise just the document's body.
 */
function container() {
  return document.fullscreenElement ?? document.body;
}

/**
 * This is where global paddings, margins, font sizes, and colors that should be
 * applied to EVERY other theme are to be set.
 *
 * specific themes should only have color overrides
 */
const themeDefaultOverrides: CustomThemeOptions = {
  chartPalette: [],
  direction: "ltr",
  palette: {},
  components: {
    MuiDataGrid: {
      styleOverrides: {
        root: {
          border: "none",
          backgroundColor: "transparent",
          overflow: "auto !important",
        },
        columnHeaderRow: {
          backgroundColor: "transparent !important",
          fontFamily: "open_sansbold !important",
        },
        columnHeader: {
          backgroundColor: "transparent", // Example header background color
        },
        columnHeaderTitle: {
          fontSize: "1rem",
          fontWeight: "bold",
          whiteSpace: "normal", // Corrected value to allow text wrapping
          overflow: "visible",
          textOverflow: "clip",
          lineHeight: "normal", // Adjust line height as needed for better readability
        },
        cell: {
          display: "flex",

          alignItems: "center",
          svg: {
            fill: ColorName.seekopsBlue,
          },
          "> span": {
            display: "flex",
            whiteSpace: "normal",
            overflow: "visible",
            textOverflow: "clip",
            lineHeight: "normal", // Adjust line height as needed for better readability
          },
        },
        row: {
          "&:hover": {
            backgroundColor: "rgba(235, 235, 235, 0.7)", // Example hover state background color
          },
        },
        virtualScroller: {
          overflow: "scroll important",
          overflowX: "scroll !important", // Always show horizontal scrollbar
          overflowY: "auto !important",
        },
      },
    },
    MuiList: {
      styleOverrides: {
        padding: {
          paddingTop: 0,
          paddingBottom: 0,
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        gutters: {
          paddingLeft: 0,
          paddingRight: 0,
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: "auto",
          margin: "0 1rem",
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        img: {
          height: "auto",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "1.31rem",
          fontWeight: "bold",
          color: "inherit",
          fill: "inherit",
          height: "2.625rem",
          padding: "0 1rem",
          "&.submit-button": {
            backgroundColor: ColorName.seekopsGreen,
          },
        },
        contained: {
          backgroundColor: ColorName.seekopsGreen,
          color: ColorName.seekopsWhite,
          fill: ColorName.seekopsWhite,
        },
        containedPrimary: {
          backgroundColor: ColorName.seekopsInfoBlue,
          color: ColorName.seekopsWhite,
          fill: ColorName.seekopsWhite,
        },
        containedSecondary: {
          backgroundColor: ColorName.seekopsRed,
          color: ColorName.seekopsWhite,
          fill: ColorName.seekopsWhite,
        },
        containedSuccess: {
          backgroundColor: ColorName.seekopsSuccess,
          color: ColorName.seekopsWhite,
          fill: ColorName.seekopsWhite,
        },
        containedWarning: {
          backgroundColor: ColorName.seekopsWarning,
          color: ColorName.seekopsBlack,
          fill: ColorName.seekopsBlack,
        },
        containedError: {
          backgroundColor: ColorName.seekopsRed,
          color: ColorName.seekopsWhite,
          fill: ColorName.seekopsWhite,
        },
        outlined: {
          borderRadius: "1.375rem",
          borderColor: ColorName.seekopsGreen,
          color: ColorName.seekopsGreen,
          fill: ColorName.seekopsGreen,
        },
        outlinedPrimary: {
          borderColor: ColorName.seekopsWhite,
          color: ColorName.seekopsWhite,
          fill: ColorName.seekopsWhite,
        },
        outlinedSecondary: {
          borderColor: ColorName.seekopsRed,
          color: ColorName.seekopsWhite,
          fill: ColorName.seekopsWhite,
        },
        outlinedSuccess: {
          borderColor: ColorName.seekopsSuccess,
          color: ColorName.seekopsSuccess,
          fill: ColorName.seekopsSuccess,
        },
        outlinedWarning: {
          borderColor: ColorName.seekopsWarning,
          color: ColorName.seekopsWarning,
          fill: ColorName.seekopsWarning,
        },
        outlinedError: {
          borderColor: ColorName.seekopsRed,
          color: ColorName.seekopsRed,
          fill: ColorName.seekopsRed,
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          "&.Mui-disabled": {
            svg: {
              color: `${ColorName.seekopsDisabled} !important`,
              fill: `${ColorName.seekopsDisabled} !important`,
            },
          },
        },
      },
    },
    MuiButtonGroup: {
      styleOverrides: {
        root: {
          borderRadius: "6px !important",
          width: "37px",
          marginBottom: "1rem",
        },
        grouped: {
          borderRadius: "6px !important",
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          fill: "inherit",
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          borderRadius: "20px",
          borderWidth: "1px",
          borderStyle: "solid",
          alignItems: "center",
          display: "flex",
          flexWrap: "nowrap",
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          backgroundColor: "transparent !important",
          color: "red",
          fontWeight: 300,
        },
        head: {
          backgroundColor: "transparent !important",
          fontSize: "1.5rem",
          fontWeight: 300,
        },
        body: {
          backgroundColor: "transparent !important",
        },
      },
    },
    MuiTableSortLabel: {
      styleOverrides: {
        root: {
          color: "inherit",
          fill: "inherit",
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          backgroundColor: "transparent",
          ".MuiTabs-flexContainer": {
            justifyContent: "space-between",
          },
        },
        indicator: {
          display: "none",
        },
        scrollButtons: {
          "&.Mui-disabled": {
            opacity: 0.3,
          },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        caption: {
          fontWeight: 300,
          fontSize: "1.5rem",
        },
        root: {
          "&.warning": {
            color: ColorName.seekopsWarning,
          },
          "&.error": {
            color: ColorName.seekopsError,
          },
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        select: {
          fontWeight: 300,
          fontSize: "11px",
        },
        selectIcon: {
          color: "inherit",
          fill: "inherit",
        },
      },
    },
    MuiCircularProgress: {
      styleOverrides: {
        root: {
          color: "blue",
          "&.failed": {
            color: "red",
          },
          "&.completed": {
            color: "green",
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          "&.navigation-icon": {
            svg: {
              height: "18px",
              width: "18px",
            },
          },
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        input: {
          textIndent: "1rem !important",
          minHeight: "1.5rem",
        },
        root: {
          borderWidth: "1px",
          borderStyle: "solid",
          color: "#c3c3c3",

          width: "100%",
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          borderRadius: "20px !important",
        },
        formControl: {
          marginTop: "1.5rem !important",
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontWeight: "bold !important",
          fontFamily: ["open_sanssemibold", "Arial", "sans-serif"].join(","),
        },
      },
    },
    MuiStepper: {
      styleOverrides: {
        root: {
          marginTop: "3rem",
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          justifyContent: "space-between",
          display: "flex",
          "&.MuiTablePagination-toolbar": {
            backgroundColor: "transparent !important",
          },
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          marginBottom: "1rem",
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: "none",
          fontFamily: "open_sanssemibold",
          "&.Mui-selected": {
            fontFamily: "open_sansbold",
            color: "rgba(11, 58, 222, 1)",
            fill: "rgba(11, 58, 222, 1)",
            svg: {
              fill: "rgba(11, 58, 222, 1)",
            },
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        paper: {
          backdropFilter: "blur(7px)",
          webkitBackdropFilter: "blur(7px)",
        },
        popper: {
          backdropFilter: "blur(7px)",
          webkitBackdropFilter: "blur(7px)",
          borderRadius: "10px",
        },
      },
      defaultProps: {
        componentsProps: {
          popper: {
            container,
          },
        },
      },
    },
  },
  shape: {
    borderRadius: 10,
  },
  typography: {
    fontSize: 12,
    fontFamily: ["open_sansregular", "Arial", "sans-serif"].join(","),
  },
};

export default themeDefaultOverrides;
