// Copyright 2021 SeekOps Inc.
// third-party
import { Reducer } from "redux";

// first-party
import {
  surveyDialogState,
  surveyDialogActionTypes,
} from "./surveyDialog.types";

// Type-safe initialState!
export const initialSurveyDialogState: surveyDialogState = {
  isOpen: false,
  surveyID: -1,
  histogramData: null,
  fluxplaneRunIds: [],
  fluxplaneRunStatuses: [],
  activeFluxplaneId: -1,
  activeFluxplaneRunStatus: null,
  flightpathByTrims: false,
  pilot: "",
  serialNumber: "",
  startTime: "",
  needRefresh: false,
};

// Thanks to Redux 4's much simpler typings, we can take away a lot of typings
// on the reducer side, everything will remain type-safe.
const surveyDialogReducer: Reducer<surveyDialogState> = (
  state = initialSurveyDialogState,
  action: any
) => {
  switch (action.type) {
    case surveyDialogActionTypes.SET_IS_OPEN: {
      return { ...state, isOpen: action.isOpen };
    }
    case surveyDialogActionTypes.SET_SURVEY_INFO: {
      return {
        ...state,
        surveyID: action.surveyID,
        histogramData: action.histogramData,
        isOpen: action.isOpen,
        pilot: action.pilot,
        serialNumber: action.serialNumber,
        startTime: action.startTime,
      };
    }
    case surveyDialogActionTypes.SET_SURVEY_ID: {
      return { ...state, surveyID: action.surveyID };
    }
    case surveyDialogActionTypes.SET_HISTOGRAM_DATA: {
      return { ...state, histogramData: action.histogramData };
    }
    case surveyDialogActionTypes.SET_FLUXPLANE_RUN_IDS: {
      return { ...state, fluxplaneRunIds: action.fluxplaneRunIds };
    }
    case surveyDialogActionTypes.SET_FLUXPLANE_RUN_STATUSES: {
      return { ...state, fluxplaneRunStatuses: action.fluxplaneRunStatuses };
    }
    case surveyDialogActionTypes.SET_SURVEY_ACTIVE_FLUXPLANE_ID: {
      return { ...state, activeFluxplaneId: action.activeFluxplaneId };
    }
    case surveyDialogActionTypes.SET_SURVEY_ACTIVE_FLUXPLANE_RUN_STATUS: {
      return {
        ...state,
        activeFluxplaneRunStatus: action.activeFluxplaneRunStatus,
      };
    }
    case surveyDialogActionTypes.SET_SITEID: {
      return { ...state, siteId: action.siteId };
    }
    case surveyDialogActionTypes.SET_SURVEY_FLUXPLANE_RUN_DATA: {
      return {
        ...state,
        fluxplaneRunIds: action.fluxplaneRunIds,
        fluxplaneRunStatuses: action.fluxplaneRunStatuses,
        activeFluxplaneId: action.activeFluxplaneId,
      };
    }
    case surveyDialogActionTypes.SET_FLIGHTPATH_BY_TRIMS: {
      return { ...state, flightpathByTrims: action.flightpathByTrims };
    }
    case surveyDialogActionTypes.SET_NEED_REFRESH: {
      return { ...state, needRefresh: action.needRefresh };
    }
    
    default:
      return state;
  }
};

// Instead of using default export, we use named exports. That way we can group
// these exports inside the `index.js` folder.
export default surveyDialogReducer;
