// Copyright 2021 SeekOps Inc.
// third-party
import { Reducer } from "redux";

// first-party
import { themeState, themeActionTypes } from "./theme.types";
import { getLocalStorageItem, setLocalStorageItem } from "../../utils/LocalStorage.utils";

/**
 *
 */
const getInitialState = (): themeState => {
  let state: themeState = {
    themeType: "light",
  };
  // check if local storage has languageSelectorState
  let existingThemeString = getLocalStorageItem("theme");
  if (existingThemeString) {
    let existingThemeState: themeState = JSON.parse(existingThemeString);

    if (existingThemeState) {
      state = {
        themeType: existingThemeState.themeType,
      };
    }
  }
  return state;
};

// Type-safe initialState!
export const initialState: themeState = getInitialState();

// Thanks to Redux 4's much simpler typings, we can take away a lot of typings on the reducer side,
// everything will remain type-safe.
const themeReducer: Reducer<themeState> = (
  state = initialState,
  action: any
) => {
  switch (action.type) {
    case themeActionTypes.SET_THEME_TYPE: {
      setLocalStorageItem(
        "theme",
        JSON.stringify({ themeType: action.themeType })
      );

      return { ...state, themeType: action.themeType };
    }
    default: {
      return state;
    }
  }
};

// Instead of using default export, we use named exports. That way we can group these exports
// inside the `index.js` folder.
export default themeReducer;
