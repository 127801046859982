// Copyright 2021 SeekOps Inc.
export enum NavigationTabs {
  SurveyPostQA = "surveys_post_quality_assurance",
  Surveys = "surveys",
  Overview = "overview",
  Import = "import",
  Sites = "sites",
  Fluxplane = "fluxplane",
  Projects = "projects",
  Project = "project",
  SurveyQuality = "survey_quality_assurance",
  Report = "report",
  HomePilot = "pilot-home",
  HomeAnalyst = "analyst-home",
  HomeAdmin = "admin-home",
  UserManagement = "user-management",
  RawData = "raw-data",
}
